.main-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .main-content {
    width: 80%;
    height: 615px;
    border-radius: 30px;
  }