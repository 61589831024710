.fp-main-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.fp-main-content {
  width: 80%;
  height: 615px;
  border-radius: 30px;
}

.fp-main-card {
  border: none;
}

.fp-form-input-field {
  width: 300px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 2.5rem;
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: 2px solid lightgray;
  border-radius: 4px;
  appearance: none;
  border-radius: var(--bs-border-radius);
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.fp-form-input-field-icon {
  display: flex;
  ;
  flex-direction: row;
}