*{
    padding: 0;
    margin: 0;
    
}


body{
    font-size: 14px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.text-center{
    text-align: center;
}
td,th{
    padding: 8px;
}
table{
    border-collapse: collapse;    
}
.table-border td,
.table-border th{
    border: solid 1px #000;
}
p{
    margin-bottom: 12px;
}
