.contactus-page {
  justify-content: center;
  align-items: center;
  margin: 45px;
  padding: 2px;
}

.contactus-page-data {
  margin: 50px;
  padding: 8px;
  text-align: center;
  background-color: whitesmoke;
}

.contactus-title {
color: white;
background-color: #0b4689;
font-weight: bold;
text-align: center;
padding: 15px 0px;
}

.contactus-subtitle {
  color: #0b4689;
  font-size: 20px;
}

.contactus-data {
  font-weight: bold;
  margin: 5px;
}