@import url("https://fonts.googleapis.com/css?family=Roboto");
html {
  font-size: 13px;
}
body {
  font-family: "Roboto", sans-serif;
}
* {
  margin: 0;
  padding: 0;
}
i {
  margin-right: 10px;
}

/*------------------------*/
input:focus,
button:focus,
.form-control:focus {
  outline: none;
  box-shadow: none;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #fff;
}
/*----------step-wizard------------*/
.d-flex {
  display: flex;
}
.justify-content-center {
  justify-content: center;
}
.align-items-center {
  align-items: center;
}

/*---------signup-step-------------*/
.bg-color {
  background-color: #333;
}
.signup-step-container {
  padding: 150px 0px;
  padding-bottom: 60px;
}

.wizard .nav-tabs {
  position: relative;
  margin-bottom: 0;
  border-bottom-color: transparent;
}

.wizard > div.wizard-inner {
  position: relative;
  margin-bottom: 50px;
  text-align: center;
}

.connecting-line {
  height: 2px;
  background: #e0e0e0;
  position: absolute;
  width: 75%;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 15px;
  z-index: 1;
}

.wizard .nav-tabs > li.active > a,
.wizard .nav-tabs > li.active > a:hover,
.wizard .nav-tabs > li.active > a:focus {
  color: #555555;
  cursor: default;
  border: 0;
  border-bottom-color: transparent;
}

span.round-tab {
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  border-radius: 50%;
  background: #fff;
  z-index: 2;
  position: absolute;
  left: 0;
  text-align: center;
  font-size: 16px;
  color: #0e214b;
  font-weight: 500;
  border: 1px solid #ddd;
}
span.round-tab i {
  color: #555555;
}
.wizard li.active span.round-tab {
  background: #0db02b;
  color: #fff;
  border-color: #0db02b;
}
.wizard li.active span.round-tab i {
  color: #5bc0de;
}
.wizard .nav-tabs > li.active > a i {
  color: #0db02b;
}

.wizard .nav-tabs > li {
  width: 25%;
}

.wizard li:after {
  content: " ";
  position: absolute;
  left: 46%;
  opacity: 0;
  margin: 0 auto;
  bottom: 0px;
  border: 5px solid transparent;
  border-bottom-color: red;
  transition: 0.1s ease-in-out;
}

.wizard .nav-tabs > li a {
  width: 30px;
  height: 30px;
  margin: 20px auto;
  border-radius: 100%;
  padding: 0;
  background-color: transparent;
  position: relative;
  top: 0;
}
.wizard .nav-tabs > li a i {
  position: absolute;
  top: -15px;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  font-weight: 700;
  color: #000;
}

.wizard .nav-tabs > li a:hover {
  background: transparent;
}

.wizard .tab-pane {
  position: relative;
  padding-top: 20px;
}

.wizard h3 {
  margin-top: 0;
}
.prev-step,
.next-step {
  font-size: 13px;
  padding: 8px 24px;
  border: none;
  border-radius: 4px;
  margin-top: 30px;
}
.next-step {
  background-color: #0db02b;
}
.skip-btn {
  background-color: #cec12d;
}
.step-head {
  font-size: 20px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 20px;
}
.term-check {
  font-size: 14px;
  font-weight: 400;
}
.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 40px;
  margin-bottom: 0;
}
.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 40px;
  margin: 0;
  opacity: 0;
}
.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 40px;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 2;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 38px;
  padding: 0.375rem 0.75rem;
  line-height: 2;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}
.footer-link {
  margin-top: 30px;
}
.all-info-container {
}
.list-content {
  margin-bottom: 10px;
}
.list-content a {
  padding: 10px 15px;
  width: 100%;
  display: inline-block;
  background-color: #f5f5f5;
  position: relative;
  color: #565656;
  font-weight: 400;
  border-radius: 4px;
}
.list-content a[aria-expanded="true"] i {
  transform: rotate(180deg);
}
.list-content a i {
  text-align: right;
  position: absolute;
  top: 15px;
  right: 10px;
  transition: 0.5s;
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #fdfdfd;
}
.list-box {
  padding: 10px;
}
.signup-logo-header .logo_area {
  width: 200px;
}
.signup-logo-header .nav > li {
  padding: 0;
}
.signup-logo-header .header-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.list-inline li {
  display: inline-block;
}
.pull-right {
  float: right;
}
/*-----------custom-checkbox-----------*/
/*----------Custom-Checkbox---------*/
input[type="checkbox"] {
  position: relative;
  display: inline-block;
  margin-right: 5px;
}
input[type="checkbox"]::before,
input[type="checkbox"]::after {
  position: absolute;
  content: "";
  display: inline-block;
}
input[type="checkbox"]::before {
  height: 16px;
  width: 16px;
  border: 1px solid #999;
  left: 0px;
  top: 0px;
  background-color: #fff;
  border-radius: 2px;
}
input[type="checkbox"]::after {
  height: 5px;
  width: 9px;
  left: 4px;
  top: 4px;
}
input[type="checkbox"]:checked::after {
  content: "";
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  transform: rotate(-45deg);
}
input[type="checkbox"]:checked::before {
  background-color: #18ba60;
  border-color: #18ba60;
}

@media (max-width: 767px) {
  .sign-content h3 {
    font-size: 40px;
  }
  .wizard .nav-tabs > li a i {
    display: none;
  }
  .signup-logo-header .navbar-toggle {
    margin: 0;
    margin-top: 8px;
  }
  .signup-logo-header .logo_area {
    margin-top: 0;
  }
  .signup-logo-header .header-flex {
    display: block;
  }
}

* {
  box-sizing: border-box;
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline label {
  margin: 5px 10px 5px 0;
}

.form-inline input {
  vertical-align: middle;
  margin: 5px 10px 5px 0;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
}

.button-section {
  margin: 3%;
}
.button {
  padding: 10px 20px;
  background-color: dodgerblue;
  border: 1px solid #ddd;
  color: white;
  cursor: pointer;
}

.button:hover {
  background-color: royalblue;
}

.remove {
  background-color: rgb(192, 53, 53);
}
.remove:hover {
  background-color: rgb(187, 43, 43);
}

.row {
  margin-bottom: 20px;
}

label {
  margin-bottom: 5px;
}

.container {
  padding: 50px 30px 10px 30px;
}

.step-head {
  margin-bottom: 35px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.btn-upload{
  margin-top: 24px;
}