.aboutus-title{
color: white;
background-color: #0b4689;
font-weight: bold;
text-align: center;
padding: 15px 0px;
}

.aboutus-sub-title{
    color: #0b4689;
    font-size: 20px;
}

.frst-section{
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-left: 26px;
}
.aboutus-img{
    /* border-radius: 5px;
    border: 1px solid gray;
    margin: 8px 5px 0px 10px;
    padding: 10px; */
    /* width: 50%; */
}
.aboutus-sidepara{
    width: 150%      ;
    margin-top: 50px;
}
