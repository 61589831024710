.header-section {
  background-color: #0b4689;
}

.hscc-logo {
  width: 200px !important;
}

.header-links {
  text-decoration: none;
  color: white;
}

.header-links:hover {
  text-decoration: underline;
  line-height: 37px;
}

.header-links:active {
  color: white;
  background: #1e90ff6e;
  line-height: 3px;
}

.main-card {
  border: none;
}

.para-content {
  height: 92%;
  border-radius: 8px 4px;
  background-size: 600px;
}

.signup-page-img {
  padding: 25px 0px;
  height: 55%;
  width: 100%;
}

.text-heading {
  color: #225c85;
}

.text-subheading {
  color: orange;
}

.text-data {
  color: #225c85;
}

.signup-page-data-img {
  width: 600px;
  height: 250px;
  object-fit: cover;
  object-position: 10% 45%;
}

.required-field {
  color: red;
}

.internal-external_radio {
  margin: 7px;
}

.c-p {
  cursor: pointer;
}

/* Dashboad Card style */

/*service*/

.services a {
  text-decoration: none;
}

.services .service-box {
  text-align: center;
}

.services .service-box h2 {
  color: #222;
  font-size: 20px;
  padding-top: 10px;
  text-decoration: none;
}

.services a .service-box:hover h2 {
  color: #a3a3a3;
}

.services .investor-box {
  background-color: whitesmoke;
  background-position: center center;
  padding: 20px;
  width: 100%;
  display: block;
  position: relative;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.services .investor-box h2 {
  font-size: 20px;
}

.services .investor-box .flip-view {
  position: absolute;
  top: 0;
  width: 100%;
  background-color: #a3a3a3;
  left: -10%;
  padding: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  transition: all ease-in-out 333ms;
}

.services .investor-box a {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
}

.services .investor-box:hover .flip-view {
  left: 0;
  visibility: visible;
  opacity: 1;
}

.reg-form-input-field {
  width: 300px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 2.5rem;
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: 2px solid lightgray;
  border-radius: 4px;
  appearance: none;
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.reg-form-input-field-icon {
  display: flex;
  flex-direction: row;
}
