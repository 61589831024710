.rac-title {
    color: white;
    background-color: #0b4689;
    font-weight: bold;
    text-align: center;
    padding: 15px 0px;
}

.li-part-color {
    color: red;
}

.rac-sub-title{
    color: #0b4689;
    font-size: 18px;
}