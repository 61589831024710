.verify-email-main-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .verify-email-main-content {
    width: 80%;
    height: 615px;
    border-radius: 30px;
  }

  .ve-submit-button{
    width: 300px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 2.5rem;    
  }